import Head from 'next/head';
import '@ozoneuniverse/canvas-components/dist/styles/build.css';
import React, { useState, createContext, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { SITE_PREVIEW } from '@src/theme/branding/site-preview';
import { BRANDING } from 'util/constants';
import Script from 'next/script';
import TopLevelLoader from '@src/components/top-level-loader';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../src/apolloClient';
import { store } from '../src/app/store';
import type { AppProps } from 'next/app';
import { IS_PROD } from 'util/constants';
import type { Session } from 'next-auth';
import { Provider } from 'react-redux';
import ShoppingCartProvider from '@ozoneuniverse/ui-components/build-pkg/src/components/shopping-cart/ShoppingCartProvider';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ConfigProvider, theme } from 'antd';
import '@ozoneuniverse/mfe-core/dist/components/css3Dfix.css';
import { GeistSans } from 'geist/font/sans';
import { useRouter } from 'next/router';

export const BrandingContext = createContext('');
export const ThemeContext = createContext({
  isDark: false,
  toggleTheme: () => {},
});

const TawkMessengerReact: any = dynamic(
  () => import('@tawk.to/tawk-messenger-react'),
  { ssr: false }
);

if (IS_PROD) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(
  React
) as any;

const ErrorView = () => (
  <div>
    <p>Error occurred please contact Ozonemetaverse support.</p>
  </div>
);

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  const [tawkOn, setTawkOn] = useState(false);
  const apolloClient = useApollo(pageProps);
  const [branding, setBranding] = useState(BRANDING);
  const [preview, setPreview] = useState(SITE_PREVIEW[BRANDING]);
  const [isDark, setIsDark] = useState(false);
  const [inEditor, setInEditor] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const isCurrentlyInEditor =
      router.pathname === '/user/studio/scene/[id]' ||
      router.pathname === '/new-template';

    setInEditor(isCurrentlyInEditor);

    if (isCurrentlyInEditor) {
      const savedTheme = localStorage.getItem('isDarkTheme');
      setIsDark(savedTheme ? JSON.parse(savedTheme) : false);
    } else {
      setIsDark(false);
    }
  }, [router.pathname]);

  const toggleTheme = useCallback(() => {
    const newThemeValue = !isDark;
    setIsDark(newThemeValue);
    localStorage.setItem('isDarkTheme', JSON.stringify(newThemeValue));
  }, [isDark]);

  function onEnableTawk() {
    setTawkOn(true);
  }

  useEffect(() => {
    if (branding === 'ZEPETO') {
      setTawkOn(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('enable-tawk-to-widget', onEnableTawk);

    return () => {
      window.removeEventListener('enable-tawk-to-widget', onEnableTawk);
    };
  });

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <link rel='icon' href={preview?.favicon} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <link rel='apple-touch-icon' href='%PUBLIC_URL%/logo192.png' />
        <title>{preview?.pageTitle}</title>
        <meta property='og:title' content={preview?.ogTitle} />
        <meta property='twitter:title' content={preview?.ogTitle} />
        <meta name='og:description' content={preview?.ogDesc} />
        <meta name='twitter:description' content={preview?.ogDesc} />
        <meta property='og:image' content={preview?.image} />
        <meta property='twitter:image' content={preview?.image} />
        <meta property='og:image:width' content='446'></meta>
        <meta property='og:image:height' content='230'></meta>
        <meta property='twitter:card' content='summary_large_image'></meta>
        <meta property='robots' content='all'></meta>
        <style global>
          {`
    body {
      margin: 0px;
      height: 100%;
      
      font-family: ${GeistSans.style.fontFamily};
      background-color: ${isDark ? '#141414' : '#ffffff'};
      color: ${isDark ? '#ffffff' : '#000000'};
      transition: background-color 0.3s ease;
    }
    
    html {
      height: 100%;
    }
    
    * {
      font-family: ${GeistSans.style.fontFamily};
      
    }
    
    body .layout {
      background: none;
      background-color: none;
      height: 100%;
    }
    
    .missing-page-root {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      height: 90vh;
      width: 100%;
      flex-direction: column;
      gap: 25px;
    }
    
    .layout .header {
      background-color: ${
        isDark ? 'rgba(0, 0, 0, 0.954)' : 'rgba(255, 255, 255, 0.954)'
      };
      min-height: 82px;
      padding: 0px;
      z-index: 1001;
      border-bottom: 0.3px solid ${isDark ? '#4c4c4c9a' : '#7b7b7b9a'};
      position: sticky;
      position: -webkit-sticky; /* Safari */
      top: 0;
    }

    .anticon img {
      max-width: fit-content;
    }

    .zepeto-logo-wrapper img {
      height: revert-layer;
    }

    .ant-btn-default svg {
      display: inline-flex; 
      vertical-align: baseline;
    }
    
    .layout .header img,
    .layout .footer img {
      object-fit: cover;
    }
    
    .layout .footer {
      background: #fff;
      padding: 0px;
      border-top: 0.2px solid #7b7b7b2c;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    }
    
    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: linear-gradient(169.69deg, #ffffff 0%, #f7efff 99.67%);
    }
    
    .content-root-wrapper {
      position: relative;
      min-height: 60vh;
      display: block;
      max-width: 2560px;
      padding-right: 10vw;
      padding-left: 10vw;
      padding-bottom: 110px;
      margin-left: auto;
      margin-right: auto;
    }
    
    #user-profile-popover .ant-popover-inner-content {
      padding: 0px;
    }
    
    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border-radius: 1px;
    }
    
    #webrtc {
      position: absolute;
      left: 250px;
      display: none;
    }
    
    .container-buttons {
      display: flex;
      justify-content: space-around;
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
    
    .scene-objects-tree .ant-tree-indent {
      width: 0px;
    }

    .disabledItem .ant-dropdown-menu-title-content:hover {
      background-color: transparent;
    }
    
    .modal-link .ant-modal-content{
      background: #333333;
    }
    
    .modal-link .ant-modal-header {
      background: #333333;
      border: none;
    }
    
    .modal-link .ant-modal-close-x {
      color: #fff;
    }
    
    .modal-link .ant-modal-header .ant-modal-title {
      color: #fff;
    }
    
    .modal-link .ant-modal-footer {
      background: #333333;
      border: none;
    }
    
    .modal-black .ant-modal-content{
      background: #0B0B0BE5;
      border: 2px solid #3A3A3A;
      padding: 48px 64px;
    }
    
    .modal-black .ant-modal-header {
      background: #0B0B0BE5;
      font-size: 32px;
    }
    
    .modal-black .ant-modal-close-x {
      color: #fff;
    }
    
    .modal-black .ant-modal-header .ant-modal-title {
      color: #fff;
    }
    
    .modal-black .ant-modal-footer {
      background: #0B0B0BE5;
    }

    .ant-menu .ant-menu-item {
      padding-left: 24px;
    }
    
    .logo {
      position: absolute;
      left: auto;
      right: auto;
      top: 16px;
      width: 140px;
      height: 48px;
      cursor: pointer;
      object-fit: cover;
    }
    
    @media only screen and (max-width: 932px) {
      .layout .footer {
        height: 100%;
      }
    }
    
    @media only screen and (max-width: 750px) {
      .content-root-wrapper {
        padding-left: 2vw;
        padding-right: 2vw;
      }
    }
    
    #__next {
      min-height: 100%;
    }
    
    body {
      overflow: auto !important;
    }
    
    .content {
      position: relative;
    }
    
    .container-onside-canvas {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      padding: 5px;
      top: 0;
    }
    
    .container-onside-canvas > * {
      z-index: 5;
    }
    
    .container-onside-canvas > div {
      display: flex;
      gap: 5px;
    }
    
    .image-icon-menu {
      width: 30px;
      height: auto;
    }
    
    .menu-side-drawer {
      width: 250px;
      background-color: #1c1c1c;
      display: flex;
      flex-direction: column;
      gap: 5px;
      overflow-y: auto;
      border-left: 1px solid rgb(67, 67, 67);
    }
    
    .menu-root-wrapper {
      display: flex;
      position: absolute;
      z-index: 6;
      height: calc(100vh - 65px);
    }
    
    .buttons-wrapper {
      display: flex;
      flex-direction: column;
    }
    
    .editor-buttons-wrapper {
      margin-top: 20px;
      margin-left: 20px;
    }
    
    .undo-redo-buttons-wrapper {
      margin-left: 20px;
      display: flex;
      gap: 10px;
    }
    
    .container-sidedrawer {
      position: relative;
      height: calc(100vh - 64px);
      overflow: auto;
      width: 248px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      background: ${isDark ? 'rgba(0, 0, 0, 0.954)' : '#fff'};    
      display: none;
    }
    
    .container-sidedrawer.active {
      display: block;
    }
    
    .container-sidedrawer > button {
      position: absolute;
      right: 0;
    }
    
    /* .container-sidedrawer > div {
      display: flex;
      overflow-x: auto;
      width: 100%;
      gap: 5px;
    } */
    
    .sidedrawer-element-disabled {
      cursor: default;
      opacity: 50%;
    }
    
    .sidedrawer-element {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      color: #fff;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      background-color: #888;
      width: 100%;
      margin-bottom: 16px;
      border-radius: 15px;
      flex-shrink: 0;
    }
    
    .sidedrawer-element-enabled {
      cursor: pointer;
    }
    
    .sidedrawer-element-selected {
      border: 3px solid #e20073;
    }
    
    .container-none-flex {
      display: none !important;
    }
    
    .container-none-flex.active {
      display: flex !important;
      overflow-y: auto;
      border-left: 1px solid rgb(67, 67, 67);
    }
    
    .menu-side-drawer .close-side-menu-button {
      position: absolute;
      right: 0px;
    }
    
    .create-scene-button {
      text-align: right;
      padding: 20px 40px;
    }
    
    .edit-scene-modal-button {
      display: flex;
      justify-content: center;
    }
    
    .public-scene-auth {
      z-index: 1000;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border: 0.3px solid rgba(255, 255, 255, 0.573);
      border-radius: 25px;
      background: #20202081;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      border: 0.3px solid rgba(255, 255, 255, 0.526);
      border-radius: 25px;
      background: #07070781;
    }
    
    .scene-editor-menu {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 200px;
      background-color: none !important;
      background: none !important;
    }
    
    .container-audio {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 100%;
      border-radius: 12px;
      border: 1px solid #888;
      margin-bottom: 16px !important;
      height: 100px;
    }
    
    #side-menu .ant-layout-sider-trigger {
      color: #333;
      border-top: 0.3px solid #7b7b7b9a;
    }
    
    #side-menu .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: scroll;
    }

    * a {
      color: #333;
    }
    
    .collectables-wrapper {
      margin-top: 30px;
    }
    
    .disable-click-events {
      pointer-events: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #000000;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #ffffff82 !important;
    }

    .inEditorTextLogo {
      overflow: hidden;
      position: relative;
    }
    `}
        </style>
      </Head>
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-XH33563KR9'
        strategy='afterInteractive'
      />
      <Script
        defer
        src='https://cloud.umami.is/script.js'
        data-website-id='d9039a78-9547-478d-92b9-e55237153081'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-XH33563KR9');
      `}
      </Script>
      <style jsx global>{`
        html {
          font-family: ${GeistSans.style.fontFamily};
        }
      `}</style>
      {IS_PROD ? (
        <ErrorBoundary FallbackComponent={ErrorView}>
          <ConfigProvider
            theme={{
              algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: {
                colorPrimary: '#e20073',
                borderRadius: 12,
                fontSize: 14,
                colorHighlight: '#4a4ae5',
              },
              components: {
                Alert: {
                  colorBorder: '#a0a0a0',
                  colorBgBase: '#b3b3b3',
                  colorIcon: '#4a4ae5',
                },
                Radio: {
                  colorPrimary: '#000',
                  colorPrimaryHover: '#663da9',
                },
                Switch: {
                  colorPrimary: '#87d068',
                },
                Tabs: {
                  itemActiveColor: 'rgba(92, 54, 154, 1)',
                  itemSelectedColor: 'rgba(92, 54, 154, 1)',
                  inkBarColor: 'rgba(92, 54, 154, 1)',
                },
              },
            }}
          >
            <ThemeContext.Provider value={{ isDark, toggleTheme }}>
              <BrandingContext.Provider value={branding}>
                <ApolloProvider client={apolloClient}>
                  <SessionProvider session={session}>
                    <ShoppingCartProvider>
                      <Provider store={store}>
                        <TopLevelLoader>
                          <Component {...pageProps} />
                          {tawkOn && (
                            <TawkMessengerReact
                              propertyId='666f69ff981b6c56477dfa0f'
                              widgetId='1i0hjc3up'
                            />
                          )}
                        </TopLevelLoader>
                      </Provider>
                    </ShoppingCartProvider>
                  </SessionProvider>
                </ApolloProvider>
              </BrandingContext.Provider>
            </ThemeContext.Provider>
          </ConfigProvider>
        </ErrorBoundary>
      ) : (
        <ConfigProvider
          theme={{
            algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
              colorPrimary: '#e20073',
              borderRadius: 12,
              fontSize: 14,
              colorHighlight: '#4a4ae5',
            },
            components: {
              Alert: {
                colorBorder: '#a0a0a0',
                colorBgBase: '#b3b3b3',
                colorIcon: '#4a4ae5',
              },
              Radio: {
                colorPrimary: '#000',
                colorPrimaryHover: '#663da9',
              },
              Switch: {
                colorPrimary: '#87d068',
              },
              Tabs: {
                itemActiveColor: 'rgba(92, 54, 154, 1)',
                itemSelectedColor: 'rgba(92, 54, 154, 1)',
                inkBarColor: 'rgba(92, 54, 154, 1)',
              },
            },
          }}
        >
          <ThemeContext.Provider value={{ isDark, toggleTheme }}>
            <BrandingContext.Provider value={branding}>
              <ApolloProvider client={apolloClient}>
                <SessionProvider session={session}>
                  <ShoppingCartProvider>
                    <Provider store={store}>
                      <TopLevelLoader>
                        <Component {...pageProps} />
                        {tawkOn && (
                          <TawkMessengerReact
                            propertyId='666f69ff981b6c56477dfa0f'
                            widgetId='1i0hjc3up'
                          />
                        )}
                      </TopLevelLoader>
                    </Provider>
                  </ShoppingCartProvider>
                </SessionProvider>
              </ApolloProvider>
            </BrandingContext.Provider>
          </ThemeContext.Provider>
        </ConfigProvider>
      )}
    </>
  );
}
